import { ExpandLess, ExpandMore, MailOutline, SmsOutlined } from '@mui/icons-material';
import { Box, Button, Collapse, Divider, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import { useState } from 'react';
import { Communication, DeliveryType } from '../../apis/communications';
import { TIME_FRIENDLY } from '../../util/dateUtils';
import Details from './Details';
import { readableNotificationType } from './utils';

type Props = {
    invoiceIdentifier?: string;
    communication: Communication;
    isFirst: boolean;
    hasTail: boolean;
};

const TIME_WIDTH = '80px';
const ICON_WIDTH = '42px';
const DIVIDER_HEIGHT = '44px';

export default function LineItem({ communication, isFirst, hasTail, invoiceIdentifier }: Readonly<Props>) {
    const [showDetails, setShowDetails] = useState(false);
    const theme = useTheme();

    const submittedAt = moment.utc(communication.submittedAt).local();
    const time = submittedAt.format(TIME_FRIENDLY);

    return (
        <Box>
            {isFirst && <DividerRow />}

            <Box sx={{ display: 'flex' }}>
                <Box sx={{ flexGrow: 1, maxWidth: TIME_WIDTH }}>
                    <Box sx={{ width: TIME_WIDTH, mt: '10px' }}>
                        <Typography>{time}</Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        flexGrow: 1,
                        maxWidth: ICON_WIDTH,
                    }}
                >
                    <Box
                        sx={{
                            width: ICON_WIDTH,
                            height: ICON_WIDTH,
                            borderRadius: ICON_WIDTH,
                            backgroundColor: grey[200],
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color:
                                communication.template.templateType === DeliveryType.EMAIL
                                    ? theme.palette.primary.dark
                                    : theme.palette.green[120],
                        }}
                    >
                        {communication.template.templateType === DeliveryType.EMAIL ? <MailOutline /> : <SmsOutlined />}
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                        <Divider orientation='vertical' />
                    </Box>
                </Box>
                <Box sx={{ flexShrink: 1, overflow: 'hidden' }}>
                    <Box>
                        <Box ml={1}>
                            <Typography>{readableNotificationType(communication.template.notificationType)}</Typography>
                        </Box>
                        <Button
                            endIcon={showDetails ? <ExpandLess /> : <ExpandMore />}
                            onClick={() => setShowDetails(!showDetails)}
                            sx={{ my: 1 }}
                        >
                            {showDetails ? 'Hide' : 'Show'} details
                        </Button>
                        <Collapse in={showDetails} sx={{ ml: 1 }}>
                            <Details communication={communication} invoiceIdentifier={invoiceIdentifier} />
                        </Collapse>
                    </Box>
                </Box>
            </Box>

            {hasTail && <DividerRow />}
        </Box>
    );
}

const DividerRow = () => {
    return (
        <Box sx={{ height: DIVIDER_HEIGHT, display: 'flex' }}>
            <Box width={TIME_WIDTH}></Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: ICON_WIDTH }}>
                <Divider orientation='vertical' sx={{ minHeight: DIVIDER_HEIGHT }} />
            </Box>
        </Box>
    );
};
