import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { Permission } from '../apis/users';

export const hasAnyPermission = async (...permissions: Permission[]): Promise<boolean> => {
    const session = await Auth.currentSession();
    return hasAnyPermissionInSession(session, ...permissions);
};

export const hasAnyPermissionInSession = (session?: CognitoUserSession, ...permissions: Permission[]): boolean => {
    if (!session) {
        return false;
    }

    const { payload } = session.getIdToken();
    const allPermissions: Permission[] = payload['cognito:groups'] ?? [];

    return permissions.find((permission) => allPermissions.includes(permission)) !== undefined;
};

export const getCurrentUsername = async (): Promise<string> => {
    const session = await Auth.currentSession();
    return session.getIdToken().payload['cognito:username'] || session.getIdToken().payload.sub;
};

export type Permissions = {
    dashboardAllowed: boolean;
    invoiceAllowed: boolean;
    clientAllowed: boolean;
    accountAllowed: boolean;
    paymentMatchingAllowed: boolean;
    userAllowed: boolean;
    invoiceUpdateAllowed: boolean;
    clientUpdateAllowed: boolean;
    accountUpdateAllowed: boolean;
    userUpdateAllowed: boolean;
    reportingAllowed: boolean;
    addingTransactionAllowed: boolean;
    pullPaymentSetupAllowed: boolean;
    enquiryConfigAllowed: boolean;
    enquiryAllowed: boolean;
    enquiryConfigUpdateAllowed: boolean;
    enquiryUpdateAllowed: boolean;
};

export const noPermissions: Permissions = {
    dashboardAllowed: false,
    invoiceAllowed: false,
    clientAllowed: false,
    accountAllowed: false,
    paymentMatchingAllowed: false,
    userAllowed: false,
    invoiceUpdateAllowed: false,
    clientUpdateAllowed: false,
    accountUpdateAllowed: false,
    userUpdateAllowed: false,
    reportingAllowed: false,
    addingTransactionAllowed: false,
    pullPaymentSetupAllowed: false,
    enquiryConfigAllowed: false,
    enquiryAllowed: false,
    enquiryConfigUpdateAllowed: false,
    enquiryUpdateAllowed: false,
};

export const extractPermissions = (session?: CognitoUserSession): Permissions => ({
    dashboardAllowed: hasAnyPermissionInSession(
        session,
        Permission.REPORTING_READ,
        Permission.INVOICE_READ,
        Permission.INVOICE_UPDATE
    ),
    invoiceAllowed: hasAnyPermissionInSession(session, Permission.INVOICE_READ, Permission.INVOICE_UPDATE),
    clientAllowed: hasAnyPermissionInSession(session, Permission.CLIENT_READ, Permission.CLIENT_UPDATE),
    accountAllowed: hasAnyPermissionInSession(session, Permission.ACCOUNT_READ, Permission.ACCOUNT_UPDATE),
    userAllowed: hasAnyPermissionInSession(session, Permission.USER_READ, Permission.USER_MANAGEMENT),
    invoiceUpdateAllowed: hasAnyPermissionInSession(session, Permission.INVOICE_UPDATE),
    clientUpdateAllowed: hasAnyPermissionInSession(session, Permission.CLIENT_UPDATE),
    accountUpdateAllowed: hasAnyPermissionInSession(session, Permission.ACCOUNT_UPDATE),
    userUpdateAllowed: hasAnyPermissionInSession(session, Permission.USER_MANAGEMENT),
    reportingAllowed: hasAnyPermissionInSession(session, Permission.REPORTING_READ),
    paymentMatchingAllowed: hasAnyPermissionInSession(session, Permission.PAYMENT_MATCHING),
    addingTransactionAllowed: hasAnyPermissionInSession(session, Permission.EXTERNAL_TRANSACTION_RECORDING),
    pullPaymentSetupAllowed: hasAnyPermissionInSession(session, Permission.PULL_PAYMENT_SETUP),
    enquiryConfigAllowed: hasAnyPermissionInSession(session, Permission.ENQUIRY_CONFIG_VIEW),
    enquiryAllowed: hasAnyPermissionInSession(session, Permission.ENQUIRY_VIEW),
    enquiryConfigUpdateAllowed: hasAnyPermissionInSession(session, Permission.ENQUIRY_CONFIG_UPDATE),
    enquiryUpdateAllowed: hasAnyPermissionInSession(session, Permission.ENQUIRY_UPDATE),
});

export type PermissionTranslation = {
    permissionName: string;
    permissionDescription: string;
};

export const translatePermission = (permission: Permission): PermissionTranslation => {
    switch (permission) {
        case Permission.USER_READ:
            return {
                permissionName: 'User read',
                permissionDescription: 'View user information.',
            };
        case Permission.USER_MANAGEMENT:
            return {
                permissionName: 'User management',
                permissionDescription: 'Manage users and their permissions.',
            };
        case Permission.CLIENT_READ:
            return {
                permissionName: 'Client read',
                permissionDescription: 'View client information.',
            };
        case Permission.CLIENT_UPDATE:
            return {
                permissionName: 'Client update',
                permissionDescription: 'Create new clients and manage client information.',
            };
        case Permission.INVOICE_READ:
            return {
                permissionName: 'Invoice read',
                permissionDescription: 'View invoice information.',
            };
        case Permission.INVOICE_UPDATE:
            return {
                permissionName: 'Invoice update',
                permissionDescription: 'Manage invoices.',
            };
        case Permission.ACCOUNT_READ:
            return {
                permissionName: 'Account read',
                permissionDescription: 'View account information.',
            };
        case Permission.ACCOUNT_UPDATE:
            return {
                permissionName: 'Account update',
                permissionDescription: 'Manage billing schedules for existing accounts.',
            };
        case Permission.REPORTING_READ:
            return {
                permissionName: 'Reporting read',
                permissionDescription: 'View reports and statistic information.',
            };
        case Permission.SETTLEMENT_READ:
            return {
                permissionName: 'Settlement read',
                permissionDescription: 'View settlement data.',
            };
        case Permission.PAYMENT_MATCHING:
            return {
                permissionName: 'Payment matching',
                permissionDescription: 'Match unsolicited payments to clients and invoices.',
            };
        case Permission.EXTERNAL_TRANSACTION_RECORDING:
            return {
                permissionName: 'Create external payment record on existing invoice',
                permissionDescription:
                    'Add a record for a payment made externally on an invoice with an outstanding balance.',
            };
        case Permission.PULL_PAYMENT_SETUP:
            return {
                permissionName: 'Create payment on existing invoice',
                permissionDescription:
                    "Charge a client's existing payment method for an invoice with an outstanding balance.",
            };
        case Permission.ENQUIRY_CONFIG_VIEW:
            return {
                permissionName: 'Enquiry configuration read',
                permissionDescription: 'View enquiry form configuration and questionnaires.',
            };
        case Permission.ENQUIRY_CONFIG_UPDATE:
            return {
                permissionName: 'Enquiry configuration update',
                permissionDescription: 'Update enquiry form configuration and questionnaires.',
            };
        case Permission.ENQUIRY_VIEW:
            return {
                permissionName: 'Enquiry read',
                permissionDescription: 'View enquiry worklist and enquiry tickets.',
            };
        case Permission.ENQUIRY_UPDATE:
            return {
                permissionName: 'Enquiry update',
                permissionDescription: 'Update and perform actions on enquiries.',
            };
        default:
            return {
                permissionName: permission,
                permissionDescription: 'Unknown permission',
            };
    }
};
