import { lowerCase, upperFirst } from 'lodash';
import { NotificationType } from '../../apis/communications';

export const readableNotificationType = (type: NotificationType) => {
    switch (type) {
        case NotificationType.SEND_INVOICE:
            return 'New payment link';
        case NotificationType.WELCOME_EMAIL_FUNDED:
        case NotificationType.WELCOME_EMAIL_IN_FULL:
        case NotificationType.UNSOLICITED_PAYMENT_RECEIVED:
            return 'Payment confirmation';
        case NotificationType.UPDATE_PAYMENT_METHOD:
            return 'Updated payment method';
        case NotificationType.SEND_ENDORSEMENT_CONFIRMATION:
            return 'Endorsement confirmation';
        case NotificationType.PAYMENT_DEFERRAL:
        case NotificationType.PAYMENT_ARRANGEMENT:
            return 'Payment arrangement setup';
        case NotificationType.CANCEL_PAYMENT_ARRANGEMENT:
            return 'Payment arrangement cancelled';
        case NotificationType.INVOICE_RENEWAL_PF:
        case NotificationType.INVOICE_RENEWAL_PIF:
            return 'Renewal reminder';
        case NotificationType.UNSOLICITED_FUNDING_DECLINED:
            return 'Premium funding application declined';
        case NotificationType.INSURED_DELINQUENT_LOAN:
        case NotificationType.INSURED_DELINQUENT_LOAN_SMS:
        case NotificationType.BANK_ACCOUNT_CORRECTION_REQUIRED:
            return 'Failed payment';
        case NotificationType.LOAN_CANCELLATION_SCHEDULED:
            return 'Cancellation';
        case NotificationType.POLICY_VERSION_QUOTE:
            return 'Quote';
        default:
            return upperFirst(lowerCase(type));
    }
};
