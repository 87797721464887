import axios from 'axios';
import { ListParams, assignDefaultListParams } from './common';

export type UserPage = {
    page: number;
    pageSize: number;
    totalPages: number;
    totalRecords: number;
    records: UserSearchResult[];
};

export type UserSearchResult = {
    uuid: string;
    email: string;
    name: string;
    username: string;
    createdDate: Date;
    groups: PermissionGroup[];
    permissions: Permission[];
};

export type User = {
    uuid: string;
    email: string;
    name: string;
    username: string;
    createdDate: Date;
    groups: PermissionGroup[];
    permissions: Permission[];
};

export type PermissionGroup = {
    uuid: string;
    name: string;
    description: string | undefined;
    isSystemGroup: boolean;
    deletedDate: Date | undefined;
    permissions: GroupPermission[];
};

export type GroupPermission = {
    uuid: string;
    permission: Permission;
    deletedDate: Date | undefined;
};

export enum Permission {
    USER_READ = 'USER_READ',
    USER_MANAGEMENT = 'USER_MANAGEMENT',

    CLIENT_READ = 'CLIENT_READ',
    CLIENT_UPDATE = 'CLIENT_UPDATE',

    INVOICE_READ = 'INVOICE_READ',
    INVOICE_UPDATE = 'INVOICE_UPDATE',

    ACCOUNT_READ = 'ACCOUNT_READ',
    ACCOUNT_UPDATE = 'ACCOUNT_UPDATE',

    REPORTING_READ = 'REPORTING_READ',

    SETTLEMENT_READ = 'SETTLEMENT_READ',
    PAYMENT_MATCHING = 'PAYMENT_MATCHING',

    EXTERNAL_TRANSACTION_RECORDING = 'EXTERNAL_TRANSACTION_RECORDING',
    PULL_PAYMENT_SETUP = 'PULL_PAYMENT_SETUP',

    ENQUIRY_CONFIG_VIEW = 'ENQUIRY_CONFIG_VIEW',
    ENQUIRY_CONFIG_UPDATE = 'ENQUIRY_CONFIG_UPDATE',
    ENQUIRY_VIEW = 'ENQUIRY_VIEW',
    ENQUIRY_UPDATE = 'ENQUIRY_UPDATE',
}

type ListUsersParams = ListParams & { query: string };

export const listUsers = async (partial?: Partial<ListUsersParams>): Promise<UserPage> => {
    const { pageSize, page, query } = assignDefaultListParams(partial);

    const url = new URL(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/users`);
    url.searchParams.append('pageSize', pageSize.toString());
    url.searchParams.append('page', page.toString());
    if (query) {
        url.searchParams.append('query', query);
    }

    return await axios.get(url.href).then(({ data }) => data);
};

export const getUser = async (uuid: string): Promise<User> => {
    return await axios.get(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/users/${uuid}`).then(({ data }) => data);
};

export const toggleUserPermissionGroup = async (uuid: string, permissionGroupUuid: string): Promise<User> => {
    return await axios
        .post(
            `${process.env.REACT_APP_BROKER_PORTAL_HOST}/users/${uuid}/toggle-permission-group?permissionGroupUuid=${permissionGroupUuid}`
        )
        .then(({ data }) => data);
};

export const toggleUserPermission = async (uuid: string, permission: Permission): Promise<User> => {
    return await axios
        .post(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/users/${uuid}/toggle-permission?permission=${permission}`)
        .then(({ data }) => data);
};

export type PermissionConfiguration = {
    groups: PermissionGroup[];
    permissions: Permission[];
};

export const getPermissionConfiguration = async (): Promise<PermissionConfiguration> => {
    return await axios
        .get(`${process.env.REACT_APP_BROKER_PORTAL_HOST}/permission-configuration`)
        .then(({ data }) => data);
};
