import { AddOutlined, Edit, EmailOutlined, SvgIconComponent } from '@mui/icons-material';
import { Box, FormControlLabel, Radio, RadioGroup, Typography, useTheme } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { Client } from '../../../apis/clients';
import { ActionType } from '../common';

type Props = {
    client: Client;
    selectedAction?: ActionType;
    setSelectedAction: (action: ActionType) => void;
    alreadyQuoted: boolean;
};

export default function Actions({ client, selectedAction, setSelectedAction, alreadyQuoted }: Readonly<Props>) {
    return (
        <RadioGroup sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} value={selectedAction}>
            {getActions(client, alreadyQuoted).map((action) => (
                <ActionRadio
                    key={action.actionType}
                    selectedAction={selectedAction}
                    setSelectedAction={setSelectedAction}
                    {...action}
                />
            ))}
        </RadioGroup>
    );
}

type Action = {
    actionType: ActionType;
    Icon: SvgIconComponent;
    title: string;
    description: string;
};
const getActions = (client: Client, alreadyQuoted: boolean) => {
    const actions = [
        {
            actionType: ActionType.SEND_QUOTE,
            Icon: EmailOutlined,
            title: alreadyQuoted ? 'Resend quote to client' : 'Send quote to client',
            description: `A quote will be emailed to ${client.contactDetails.email.address} and can be endorsed at a later date.`,
        },
        {
            actionType: ActionType.CREATE_ENDORSEMENT,
            Icon: AddOutlined,
            title: 'Create endorsement',
            description: `Finalise and apply the policy changes now. ${client.displayName} will receive an email containing an updated policy and payment schedule.`,
        },
    ];
    if (!alreadyQuoted) {
        actions.push({
            actionType: ActionType.SAVE_DRAFT,
            Icon: Edit,
            title: 'Save as draft',
            description: 'You can return to edit, endorse, or delete it later.',
        });
    }
    return actions;
};

type ActionRadioProps = Action & {
    selectedAction?: ActionType;
    setSelectedAction: (action: ActionType) => void;
};

const ActionRadio = ({
    title,
    description,
    Icon,
    actionType,
    selectedAction,
    setSelectedAction,
}: Readonly<ActionRadioProps>) => {
    const theme = useTheme();
    const selected = selectedAction === actionType;

    return (
        <FormControlLabel
            sx={{
                display: 'flex',
                gap: 1,
                m: 0,
                p: 2,
                pl: 1,
                border: grey[400],
                borderWidth: '1px',
                borderStyle: 'solid',
                borderRadius: theme.shape.borderRadius + 'px',
                backgroundColor: selected ? blue['50'] : grey['100'],
                alignItems: 'center',
            }}
            onClick={() => setSelectedAction(actionType)}
            control={<Radio value={actionType} size='small' />}
            label={
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Box
                        sx={{
                            minWidth: '56px',
                            width: '56px',
                            height: '56px',
                            borderRadius: '56px',
                            backgroundColor: blue['100'],
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Icon color='primary' />
                    </Box>
                    <Box>
                        <Typography variant='h6'>{title}</Typography>
                        <Typography variant='caption'>{description}</Typography>
                    </Box>
                </Box>
            }
        />
    );
};
