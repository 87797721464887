import { ArrowForward, ExpandLess, ExpandMore } from '@mui/icons-material';
import {
    Box,
    Button,
    Collapse,
    Divider,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { defaultPageSize } from '../../apis/common';
import { Communication, DeliveryType, NotificationType, searchCommunications } from '../../apis/communications';
import { FetchState, FetchStateType } from '../../hooks/useFetch';
import { DATE_COMPACT, DATE_SERVER_FORMAT, getValidateDates } from '../../util/dateUtils';
import { FetchWrapper } from '../FetchWrapper';
import Timeline from './Timeline';
import { readableNotificationType } from './utils';

type Props = {
    invoiceIdentifier?: string;
    insuredIdentifier?: string;
};

const minDate = moment().subtract(5, 'years');
const maxDate = moment();

export default function Communications({ invoiceIdentifier, insuredIdentifier }: Readonly<Props>) {
    const [showFilter, setShowFilter] = useState(false);
    const [loadState, setLoadState] = useState(FetchStateType.LOADING);
    const [communications, setCommunications] = useState<Communication[]>([]);
    const [totalRecords, setTotalRecords] = useState(0);

    const [page, setPage] = useState(0);
    const [sentStartDate, setSentStartDate] = useState<string>();
    const [sentEndDate, setSentEndDate] = useState<string>();
    const [sentVia, setSentVia] = useState<SentViaType>();
    const [messageType, setMessageType] = useState<MessageType>();

    useEffect(() => {
        setLoadState(FetchStateType.LOADING);

        const deliveryType = sentVia === All.ALL ? undefined : sentVia;
        searchCommunications({
            page,
            pageSize: defaultPageSize,
            sentStartDate,
            sentEndDate,
            insuredIdentifier,
            invoiceIdentifier,
            deliveryType,
            notificationTypes: getNotificationTypes(messageType),
        })
            .then((value) => {
                if (page === 0) {
                    setCommunications(value.records);
                } else {
                    setCommunications((prev) => [...prev, ...value.records]);
                }
                setTotalRecords(value.totalRecords);
                setLoadState(FetchStateType.SUCCESS);
            })
            .catch(() => {
                setLoadState(FetchStateType.ERROR);
            });
    }, [page, sentStartDate, sentEndDate, invoiceIdentifier, insuredIdentifier, sentVia, messageType]);

    const validDates = getValidateDates(minDate, maxDate);

    const handleStartDateChange = (startDateInput: Moment | null) => {
        if (startDateInput == null) {
            setPage(0);
            setSentStartDate(undefined);
        }

        if (!validDates(startDateInput, sentEndDate ? moment(sentEndDate, DATE_SERVER_FORMAT) : maxDate)) {
            return;
        }
        setPage(0);
        setSentStartDate(startDateInput!.format(DATE_SERVER_FORMAT));
    };

    const handleEndDateChange = (endDateInput: Moment | null) => {
        if (endDateInput == null) {
            setPage(0);
            setSentEndDate(undefined);
        }

        if (!validDates(sentStartDate ? moment(sentStartDate, DATE_SERVER_FORMAT) : minDate, endDateInput)) {
            return;
        }
        setPage(0);
        setSentEndDate(endDateInput!.format(DATE_SERVER_FORMAT));
    };

    const getLoadMore = () => {
        if (loadState === FetchStateType.SUCCESS && totalRecords > communications.length) {
            return (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center', mt: 4 }}>
                    <Button variant='contained' onClick={() => setPage(page + 1)}>
                        Load more
                    </Button>
                </Box>
            );
        }

        return null;
    };

    const handleChangeSentVia = (event: SelectChangeEvent) => {
        setPage(0);
        setSentVia(event.target.value as SentViaType);
        setMessageType(undefined);
    };

    const handleChangeMessageType = (messageType: MessageType) => {
        setPage(0);
        setMessageType(messageType);
    };

    return (
        <Paper variant='flat' sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: invoiceIdentifier ? 'flex-end' : 'space-between', mb: 2 }}>
                {!invoiceIdentifier && <Typography variant='h2'>Communications</Typography>}
                <Button
                    onClick={() => setShowFilter(!showFilter)}
                    endIcon={showFilter ? <ExpandLess /> : <ExpandMore />}
                >
                    Filter
                </Button>
            </Box>
            <Collapse in={showFilter}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 3,
                        mb: showFilter ? 3 : 0,
                        flexWrap: 'wrap',
                    }}
                >
                    <Box>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <InputLabel htmlFor='startDate'>Date range</InputLabel>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    <DatePicker
                                        onChange={handleStartDateChange}
                                        slotProps={{
                                            textField: {
                                                id: 'startDate',
                                                size: 'small',
                                                sx: { width: '180px' },
                                            },
                                        }}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        format={DATE_COMPACT}
                                    />
                                    <Divider sx={{ width: '5px' }} color='action' />
                                    <DatePicker
                                        onChange={handleEndDateChange}
                                        slotProps={{
                                            textField: {
                                                id: 'endDate',
                                                size: 'small',
                                                sx: { width: '180px' },
                                            },
                                        }}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        format={DATE_COMPACT}
                                    />
                                </Box>
                            </Box>
                        </LocalizationProvider>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-end' }}>
                        <Box width={180}>
                            <InputLabel htmlFor='sent-via-select'>Sent via</InputLabel>
                            <Select
                                id='sent-via-select'
                                value={sentVia ?? ''}
                                onChange={handleChangeSentVia}
                                size='small'
                                fullWidth
                            >
                                <MenuItem value={All.ALL}>All</MenuItem>
                                <MenuItem value={DeliveryType.EMAIL}>Email</MenuItem>
                                <MenuItem value={DeliveryType.SMS}>SMS</MenuItem>
                            </Select>
                        </Box>
                        <ArrowForward color='action' fontSize='small' sx={{ mb: '10px' }} />
                        <Box width={180}>
                            <InputLabel htmlFor='message-type-select'>Message type</InputLabel>
                            {sentVia === DeliveryType.SMS ? (
                                <SmsMessageTypeMenuItems
                                    messageType={messageType}
                                    setMessageType={handleChangeMessageType}
                                />
                            ) : (
                                <EmailMessageTypeMenuItems
                                    messageType={messageType}
                                    setMessageType={handleChangeMessageType}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            </Collapse>

            <Divider sx={{ mb: 3 }} />

            {(loadState === FetchStateType.SUCCESS || page !== 0) && (
                <Timeline communications={communications} invoiceIdentifier={invoiceIdentifier} />
            )}
            <FetchWrapper state={{ type: loadState } as FetchState<void>} SuccessComponent={() => null} />

            {getLoadMore()}
        </Paper>
    );
}

enum All {
    ALL = 'ALL',
}

type SentViaType = DeliveryType | All;
type MessageType = NotificationType | All;

const getNotificationTypes = (messageType?: MessageType): NotificationType[] | undefined => {
    if (messageType == null || messageType === All.ALL) {
        return undefined;
    }

    if (messageType === NotificationType.WELCOME_EMAIL_FUNDED) {
        return [
            NotificationType.WELCOME_EMAIL_FUNDED,
            NotificationType.WELCOME_EMAIL_IN_FULL,
            NotificationType.UNSOLICITED_PAYMENT_RECEIVED,
        ];
    }

    if (messageType === NotificationType.INVOICE_RENEWAL_PF) {
        return [NotificationType.INVOICE_RENEWAL_PF, NotificationType.INVOICE_RENEWAL_PIF];
    }

    if (messageType === NotificationType.INSURED_DELINQUENT_LOAN) {
        return [
            NotificationType.INSURED_DELINQUENT_LOAN,
            NotificationType.INSURED_DELINQUENT_LOAN_SMS,
            NotificationType.BANK_ACCOUNT_CORRECTION_REQUIRED,
        ];
    }

    if (messageType === NotificationType.PAYMENT_ARRANGEMENT) {
        return [NotificationType.PAYMENT_ARRANGEMENT, NotificationType.PAYMENT_DEFERRAL];
    }

    return [messageType];
};

type MessageTypeSelectProps = {
    messageType?: MessageType;
    setMessageType: (type: MessageType) => void;
};

const selectableEmailTypes = [
    NotificationType.POLICY_VERSION_QUOTE,
    NotificationType.SEND_ENDORSEMENT_CONFIRMATION,
    NotificationType.INSURED_DELINQUENT_LOAN,
    NotificationType.SEND_INVOICE,
    NotificationType.CANCEL_PAYMENT_ARRANGEMENT,
    NotificationType.PAYMENT_ARRANGEMENT,
    NotificationType.WELCOME_EMAIL_FUNDED,
    NotificationType.UNSOLICITED_FUNDING_DECLINED,
    NotificationType.INVOICE_RENEWAL_PF,
    NotificationType.UPDATE_PAYMENT_METHOD,
    NotificationType.LOAN_CANCELLATION_SCHEDULED,
];

const EmailMessageTypeMenuItems = ({ messageType, setMessageType }: Readonly<MessageTypeSelectProps>) => {
    return (
        <Select
            id='message-type-select'
            value={messageType ?? ''}
            onChange={(event: SelectChangeEvent) => {
                setMessageType(event.target.value as MessageType);
            }}
            size='small'
            fullWidth
        >
            <MenuItem value={All.ALL}>All</MenuItem>
            {selectableEmailTypes.map((type) => (
                <MenuItem value={type} key={type}>
                    {readableNotificationType(type)}
                </MenuItem>
            ))}
        </Select>
    );
};

const SmsMessageTypeMenuItems = ({ messageType, setMessageType }: Readonly<MessageTypeSelectProps>) => {
    return (
        <Select
            id='message-type-select'
            value={messageType ?? ''}
            onChange={(event: SelectChangeEvent) => {
                setMessageType(event.target.value as MessageType);
            }}
            size='small'
            fullWidth
        >
            <MenuItem value={All.ALL}>All SMS</MenuItem>
        </Select>
    );
};
